// Home Page Styles Only
.home {
	.half-and-half__wrapper {max-width: 65rem;}

	@include media($screen-md) {
		.half-and-half__left--content {padding-right: 2rem;}
		.half-and-half__right--content {padding-left: 2rem;}

		.half-and-half__left--content,
		.half-and-half__right--content {
			padding-bottom: 3rem;
		}
	}
}
/**
 * Project font designations for markup elements per tech spec doc
 * DBS>Interactive
 */
/**
 * To add a new fonts:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * 	https://www.fontsquirrel.com/tools/webfont-generator
 *
 * 	Download the resulting files and copy the eot, svg, ttf, woff, and woff2
 * 	files into the appropriate directory.
 *
 * 	Make sure that the font-weight and font-style match the appropriate font.
 */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
	font-display: swap;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Thin.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
	font-display: swap;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraLight.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
	font-display: swap;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Light.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Light.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
	font-display: swap;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Regular.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
	font-display: swap;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Medium.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
	font-display: swap;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Bold.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraBold.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraBold.woff') format('woff');
	font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Black.woff2') format('woff2'),
         url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Black.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'lato';
	font-style: normal;
	font-weight: 300;
	src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Light.woff2') format('woff2'),
		 url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Light.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'lato';
	font-style: normal;
	font-weight: 400;
	src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Regular.woff2') format('woff2'),
		 url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Regular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'lato';
	font-style: normal;
	font-weight: 700;
	src: url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Bold.woff2') format('woff2'),
		 url('https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Bold.woff') format('woff');
	font-display: swap;
}

/**
* Primary Font Family
* @group Typography
*/

$montserrat: "Montserrat", sans-serif;
$lato: "lato", sans-serif;

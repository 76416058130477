@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes slideRight {
    0% {
        transform: translate3d(-3rem,0,0);
    }

    100% {
        transform: translate3d(0,0,0,);
    }
}


@keyframes slideUp {
    0% {
        transform: translate3d(0,2rem,0);
    }

    100% {
        transform: translate3d(0,0,0,);
    }
}

/**
 * Slate Container Styles
 * DBS>Interactive
 *
 * This module is made up of three classes and a helpful mixin.
 *
 * The mixin constrained-width() can be used to give a max-width to a
 * particular section.
 *
 * When givin a choice, prefer to add the classnames in the HTML rather than
 * using the constrained-width mixin. This will help to keep the SCSS files
 * less complicated and more efficient.
 *
 * 		container-narrow
 * 		container-default (container-medium, container, or contain)
 * 		container-wide
 */


/**
 * Responsive layout mixin to constrain a width to containerSize.
 */
@mixin constrained-width($width: $containerSize) {
	margin: 3rem auto;

	@include media ($containerSizeNarrow) {
		margin: 4rem auto;
		max-width: $width;
	}
}

.contain, .container,
.layout.container-medium,
.layout.container-default { @include constrained-width($containerSize); }
.layout.container-narrow { @include constrained-width($containerSizeNarrow); }
.layout.container-wide { @include constrained-width($containerSizeWide); }

// Note the following override: The full width layout fills the entire viewport width, 
// for it would otherwise act much like the default layout. What we really want to do 
// is limit the width of the content inside the layout, which the styles below seek to do.
.layout.layout-full-width,
.layout.contact-cta {
	margin: 2rem auto;
	.full-width-container {
		@include constrained-width($containerSize);
		margin: 0rem auto !important;
	}
	&.container-default,
	&.container-narrow,
	&.container-wide {
		max-width: none;
	}
}

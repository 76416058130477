/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * TODO: Document
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Theme Variables - colors, font sizes, etc.
 * DBS>Interactive
 */
/**
 * Project font designations for markup elements per tech spec doc
 * DBS>Interactive
 */
/**
 * To add a new fonts:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * 	https://www.fontsquirrel.com/tools/webfont-generator
 *
 * 	Download the resulting files and copy the eot, svg, ttf, woff, and woff2
 * 	files into the appropriate directory.
 *
 * 	Make sure that the font-weight and font-style match the appropriate font.
 */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Thin.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Thin.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraLight.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Light.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Light.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Regular.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Medium.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Medium.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Bold.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Bold.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraBold.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-ExtraBold.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Black.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/Montserrat/subset-Montserrat-Black.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: 'lato';
  font-style: normal;
  font-weight: 300;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Light.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Light.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: 'lato';
  font-style: normal;
  font-weight: 400;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Regular.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Regular.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: 'lato';
  font-style: normal;
  font-weight: 700;
  src: url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Bold.woff2") format("woff2"), url("https://d2bi2rpyabw4cc.cloudfront.net/wp-content/themes/kaocollins/library/fonts/lato/subset-Lato-Bold.woff") format("woff");
  font-display: swap; }

/**
* Primary Font Family
* @group Typography
*/
/**
 * General Theme Type classes
 *
 * These classes are generic repeatable patterns of type styles that can be
 * extended by other classes to maintain consistency throughout the theme.
 *
 * Recommended usage:
 * 		@extend .button-type;
 */
.default-type, .button-type, html, body {
  color: #323232;
  font-family: "lato", sans-serif;
  letter-spacing: 0.025em; }

.button-type {
  font-weight: bold; }

.header-type, .nav-type, nav, h1, h2, h3, h4, h5, h6 {
  color: #323232;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  line-height: 1.25; }

.nav-type {
  letter-spacing: 0.1em;
  text-transform: uppercase; }

/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Default Typography
 */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth; }
  @media (min-width: 42em) {
    html {
      font-size: 0.95em; } }
  @media (min-width: 60em) {
    html {
      font-size: 1em; } }
  @media (min-width: 90em) {
    html {
      font-size: 1.1em; } }

body {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.75; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-rendering: optimizeLegibility; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    text-decoration: none; }

.h1, h1 {
  font-size: 2.53835em;
  line-height: 1;
  font-weight: 900;
  font-weight: 900; }
  @media (min-width: 42em) {
    .h1, h1 {
      font-size: 3.17294em; } }

.h2, h2 {
  font-size: 1.47015em;
  font-weight: 700; }
  @media (min-width: 42em) {
    .h2, h2 {
      font-size: 1.83769em; } }

.h3, h3 {
  font-size: 1.11883em; }
  @media (min-width: 42em) {
    .h3, h3 {
      font-size: 1.39854em; } }

.h4, h4 {
  font-size: 0.85147em; }
  @media (min-width: 42em) {
    .h4, h4 {
      font-size: 1.06434em; } }

.h5, h5 {
  font-size: 0.648em; }
  @media (min-width: 42em) {
    .h5, h5 {
      font-size: 0.81em; } }

.h6, h6 {
  font-size: 0.49315em; }
  @media (min-width: 42em) {
    .h6, h6 {
      font-size: 0.61644em; } }

a {
  color: #008571;
  transition: color ease-in-out 0.25s;
  word-break: break-word; }
  a:hover, a:focus, a:active {
    color: #005c4e; }

p a {
  color: #008571; }
  p a:hover, p a:focus, p a:active {
    color: #005c4e; }

.large-text__one {
  font-size: 2.7rem; }
  @media screen and (min-width: 42em) {
    .large-text__one {
      font-size: 10vw; } }
  @media screen and (min-width: 88.889em) {
    .large-text__one {
      font-size: 9rem; } }

.montserrat {
  font-family: "Montserrat", sans-serif !important; }

.lato {
  font-family: "lato", sans-serif !important; }

.white {
  color: #ffffff; }

.black {
  color: #000000; }

.green {
  color: #008571; }

.dark-green {
  color: #008571; }

.light-green {
  color: #00A88E; }

.graylight {
  color: #757575; }

.gray {
  color: #595959; }

.thin {
  font-weight: 400; }

.bold {
  font-weight: 700; }

.extra-bold {
  font-weight: 900; }

.caps {
  text-transform: uppercase; }

hr {
  background: #e5e5e5;
  height: 2px;
  margin: 3rem 0;
  width: 100%; }

h2.big-white,
.h2.big-white {
  color: #ffffff;
  font-size: 2.05rem; }
  @media screen and (min-width: 66.667em) {
    h2.big-white,
    .h2.big-white {
      font-size: 2.5rem; } }
  .partial-homepage-header .big-white {
    font-size: 1.47015em;
    font-weight: 700; }
    @media (min-width: 42em) {
      .partial-homepage-header .big-white {
        font-size: 1.83769em; } }

h2.big-purple,
.h2.big-purple {
  font-size: 2.05rem; }
  @media screen and (min-width: 66.667em) {
    h2.big-purple,
    .h2.big-purple {
      font-size: 2.5rem; } }

h2.big-pink {
  font-size: 2.05rem; }
  @media screen and (min-width: 66.667em) {
    h2.big-pink {
      font-size: 2.5rem; } }

h2.big-green {
  color: #008571 !important;
  font-size: 2.05rem; }
  @media screen and (min-width: 66.667em) {
    h2.big-green {
      font-size: 2.5rem; } }

h2.big-green-dark {
  color: #008571;
  font-size: 2.05rem; }
  @media screen and (min-width: 66.667em) {
    h2.big-green-dark {
      font-size: 2.5rem; } }

.bold {
  font-weight: 500; }

em {
  color: #757575;
  font-size: 2rem;
  font-style: normal; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }

.eyebrow {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 4.27px;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.dropcap {
  color: #00A88E;
  float: left;
  font-size: 6.25em;
  font-weight: 400;
  line-height: 90px;
  margin-bottom: -.031em;
  margin-top: .03em;
  padding-right: .5rem; }

.font_small,
small {
  font-size: 0.707em; }

.font-size-xs, .fs-xs {
  font-size: .875rem; }

.font-size-sm, .fs-sm {
  font-size: .9375rem; }

.font-size-md, .fs-md {
  font-size: 1rem; }

.font-size-lg, .fs-lg, .intro-text {
  font-size: 1.125rem; }

.font-size-xl, .fs-xl {
  font-size: 1.25rem; }

.font-size-xxl, .fs-xxl {
  font-size: 1.375rem; }

.font-size-xxxl, .fs-xxxl {
  font-size: 1.5rem; }

.uppercase {
  text-transform: uppercase; }

.text-center {
  text-align: center; }

.assistive,
.screen-reader-text,
.hide-text {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

.screen-reader-text:active,
.screen-reader-text:focus {
  height: auto;
  width: auto;
  clip: auto;
  margin: 0;
  overflow: visible;
  position: static; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slideRight {
  0% {
    transform: translate3d(-3rem, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes slideUp {
  0% {
    transform: translate3d(0, 2rem, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  color: #ffffff !important;
  display: inline-block;
  float: right;
  font-family: sans-serif;
  font-size: 1rem;
  height: 2em;
  position: relative;
  text-decoration: none;
  width: 1em; }
  #dbs-chev strong {
    font-size: 0.60883em;
    display: block;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 21em; }
    @media (min-width: 42em) {
      #dbs-chev strong {
        font-size: 0.76104em; } }
  #dbs-chev .text {
    background: rgba(0, 0, 0, 0.9);
    display: inline-block;
    float: left;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev a {
    color: #ffffff;
    text-decoration: none; }
    #dbs-chev a:hover {
      color: #cccccc; }
  #dbs-chev polygon {
    fill: #ffffff; }
  #dbs-chev.hover span, #dbs-chev:hover span {
    max-width: 23em;
    float: left; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
#site-footer {
  margin-top: auto;
  background: #202020;
  color: #ffffff;
  overflow: hidden;
  padding: 0; }
  @media screen and (min-width: 60em) {
    #site-footer {
      font-size: .875em; } }
  #site-footer #site-footer__logo img {
    max-height: 3em;
    max-width: 12em;
    display: block;
    width: 100%; }
  #site-footer ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  #site-footer a {
    color: #ffffff;
    display: inline-block;
    font-family: "lato", sans-serif;
    font-weight: 400;
    padding: 0;
    text-decoration: none;
    word-break: normal; }
    #site-footer a:hover, #site-footer a:focus {
      color: #008571; }
  #site-footer .footer-heading {
    color: #ffffff;
    display: block;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: .5rem; }
  #site-footer #site-footer__content #container-top {
    border-top: 1px solid rgba(59, 74, 89, 0.4);
    margin: 0 auto;
    max-width: 77rem;
    padding: 1em 0; }
    @media screen and (min-width: 60em) {
      #site-footer #site-footer__content #container-top {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between; } }
    #site-footer #site-footer__content #container-top .footer-column {
      display: inline-block;
      width: 100%;
      padding: 2rem 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
      @media screen and (min-width: 60em) {
        #site-footer #site-footer__content #container-top .footer-column {
          border: none;
          padding: 2rem 3vw;
          width: 33.33%; }
          #site-footer #site-footer__content #container-top .footer-column#middle-column {
            position: relative; }
            #site-footer #site-footer__content #container-top .footer-column#middle-column:before, #site-footer #site-footer__content #container-top .footer-column#middle-column:after {
              content: '';
              height: 85%;
              position: absolute;
              top: 50%;
              transform: translateY(-50%); }
            #site-footer #site-footer__content #container-top .footer-column#middle-column:before {
              border-left: 1px solid rgba(255, 255, 255, 0.4);
              left: 0; }
            #site-footer #site-footer__content #container-top .footer-column#middle-column:after {
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              right: 0; } }
    #site-footer #site-footer__content #container-top #first-row #addresses > div {
      margin: 2em 0; }
    #site-footer #site-footer__content #container-top #second-row #nav-wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: 2em; }
      #site-footer #site-footer__content #container-top #second-row #nav-wrapper #site-footer-nav__main-menu {
        width: 100%; }
        #site-footer #site-footer__content #container-top #second-row #nav-wrapper #site-footer-nav__main-menu li {
          display: block;
          padding-bottom: 0; }
          @media screen and (min-width: 60em) {
            #site-footer #site-footer__content #container-top #second-row #nav-wrapper #site-footer-nav__main-menu li {
              padding-bottom: .5em; } }
          #site-footer #site-footer__content #container-top #second-row #nav-wrapper #site-footer-nav__main-menu li button {
            display: none; }
    #site-footer #site-footer__content #container-top #fourth-row a {
      display: block; }
    #site-footer #site-footer__content #container-top #second-row a,
    #site-footer #site-footer__content #container-top #fourth-row a {
      padding: .25em 0; }
  #site-footer #site-footer__social-media .site-footer__social-link {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 100%;
    display: inline-block;
    font-size: 1.2em;
    height: 2em;
    line-height: 1.9;
    margin-left: .5rem;
    text-align: center;
    transition: .35s all;
    width: 2em; }
    #site-footer #site-footer__social-media .site-footer__social-link svg {
      fill: #ffffff;
      height: 100%;
      width: 0.8em;
      height: auto;
      transition: fill .35s; }
    #site-footer #site-footer__social-media .site-footer__social-link:hover, #site-footer #site-footer__social-media .site-footer__social-link:focus {
      border: 1px solid #00A88E; }
      #site-footer #site-footer__social-media .site-footer__social-link:hover svg, #site-footer #site-footer__social-media .site-footer__social-link:focus svg {
        fill: #00A88E; }
  #site-footer #site-footer__credits {
    padding: 1em; }
    #site-footer #site-footer__credits .copyright {
      margin-bottom: 1rem; }
    @media screen and (min-width: 60em) {
      #site-footer #site-footer__credits {
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: .8rem; }
        #site-footer #site-footer__credits #site-footer__social-media {
          margin-left: auto;
          margin-right: auto; }
        #site-footer #site-footer__credits .copyright {
          margin-bottom: 0; } }
    @media screen and (min-width: 76rem) {
      #site-footer #site-footer__credits {
        font-size: 1rem; } }
    #site-footer #site-footer__credits #navigation {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-top: 1em; }
      @media screen and (min-width: 60em) {
        #site-footer #site-footer__credits #navigation {
          margin-top: 0em; } }
      @media screen and (min-width: 60em) {
        #site-footer #site-footer__credits #navigation nav {
          padding: 0 2em; } }
      #site-footer #site-footer__credits #navigation li {
        border-right: 1px solid rgba(59, 74, 89, 0.4);
        display: inline-block;
        margin-right: .5em;
        padding-right: .75em; }
        #site-footer #site-footer__credits #navigation li:last-child {
          border: none; }
        @media screen and (max-width: 41.999em) {
          #site-footer #site-footer__credits #navigation li {
            margin-bottom: .75rem; } }
  #site-footer .container-top,
  #site-footer .site-footer__credits {
    margin: 0 auto; }
  #site-footer .container-top {
    max-width: 88.889rem; }
  #site-footer .site-footer__credits {
    max-width: calc(88.889rem - 4rem); }

.wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {
  display: none; }

/**
 * Slate Container Styles
 * DBS>Interactive
 *
 * This module is made up of three classes and a helpful mixin.
 *
 * The mixin constrained-width() can be used to give a max-width to a
 * particular section.
 *
 * When givin a choice, prefer to add the classnames in the HTML rather than
 * using the constrained-width mixin. This will help to keep the SCSS files
 * less complicated and more efficient.
 *
 * 		container-narrow
 * 		container-default (container-medium, container, or contain)
 * 		container-wide
 */
/**
 * Responsive layout mixin to constrain a width to containerSize.
 */
.contain, .container,
.layout.container-medium,
.layout.container-default {
  margin: 3rem auto; }
  @media screen and (min-width: 42.667rem) {
    .contain, .container,
    .layout.container-medium,
    .layout.container-default {
      margin: 4rem auto;
      max-width: 77rem; } }

.layout.container-narrow {
  margin: 3rem auto; }
  @media screen and (min-width: 42.667rem) {
    .layout.container-narrow {
      margin: 4rem auto;
      max-width: 42.667rem; } }

.layout.container-wide {
  margin: 3rem auto; }
  @media screen and (min-width: 42.667rem) {
    .layout.container-wide {
      margin: 4rem auto;
      max-width: 88.889rem; } }

.layout.layout-full-width,
.layout.contact-cta {
  margin: 2rem auto; }
  .layout.layout-full-width .full-width-container,
  .layout.contact-cta .full-width-container {
    margin: 3rem auto;
    margin: 0rem auto !important; }
    @media screen and (min-width: 42.667rem) {
      .layout.layout-full-width .full-width-container,
      .layout.contact-cta .full-width-container {
        margin: 4rem auto;
        max-width: 77rem; } }
  .layout.layout-full-width.container-default, .layout.layout-full-width.container-narrow, .layout.layout-full-width.container-wide,
  .layout.contact-cta.container-default,
  .layout.contact-cta.container-narrow,
  .layout.contact-cta.container-wide {
    max-width: none; }

/**
 * Default layout styles
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
.site {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative; }

.site-content {
  background: #ffffff;
  clear: both; }

/**
 * Basic generic styles for all layouts
 */
.layout {
  overflow: hidden; }
  .layout:last-child {
    margin-bottom: 0; }
  .layout .blockquote {
    text-align: left;
    max-width: 900px;
    font-weight: 500;
    margin: 5em auto;
    padding: 0.5em 10px;
    padding-left: 40px;
    border: none; }
  .layout blockquote {
    border: none; }
  .layout .blockquote::before {
    content: open-quote;
    position: absolute;
    margin-top: -0.5em;
    font-size: 7em;
    font-family: Georgia; }

.layout:not(.layout-content_with_sidebar) .layout:last-child.overflow-content {
  margin-bottom: 0; }

.thumbnail-text .cell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start; }
  .thumbnail-text .cell > :first-child {
    margin-right: 1em; }
  .thumbnail-text .cell > img {
    max-width: 25%; }
  .thumbnail-text .cell > .content {
    -ms-flex: 1;
    flex: 1; }

.text-card .cell,
.image-card .cell {
  margin: 3rem 0 3rem 1.5rem;
  padding: 0; }
  .text-card .cell:last-of-type,
  .image-card .cell:last-of-type {
    margin-right: 0; }
  .text-card .cell:first-of-type,
  .image-card .cell:first-of-type {
    margin-left: 0; }
  .text-card .cell .content,
  .image-card .cell .content {
    padding: 0 1.5rem; }

.featured-inks__container {
  padding: 0 1.5rem; }

.featured-inks__heading {
  margin-bottom: 2rem; }

.featured-inks__block-img {
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  padding-bottom: 36%;
  position: relative; }
  .featured-inks__block-img img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .featured-inks__block-img img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.featured-inks__block-text {
  padding: 0 1rem; }
  .featured-inks__block-text a {
    color: #000000 !important;
    font-weight: bold;
    text-decoration: none; }

@media screen and (max-width: 41.999em) {
  .featured-inks__block {
    margin-bottom: 2rem; } }

@media screen and (min-width: 42em) {
  .featured-inks__heading {
    margin-bottom: 3rem; }
  .featured-inks__blocks {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .featured-inks__block {
    padding-bottom: 1rem;
    position: relative;
    width: calc(50% - 1.5rem); }
    .featured-inks__block:after {
      height: 1.75rem;
      width: 1.75rem;
      background: url("/wp-content/themes/kaocollins/library/icons/src/circle-right1-green.svg") no-repeat center;
      background-size: contain;
      content: '';
      position: absolute;
      bottom: 0;
      left: 1rem;
      transition: all .25s; }
    .featured-inks__block:hover:after, .featured-inks__block:focus:after {
      transform: translateX(0.25rem); } }

@media screen and (min-width: 60em) {
  .featured-inks__block {
    width: calc(25% - 3rem); } }

/**
 * Half and half flex layout
 * DBS>Interactive
 */
.half-and-half {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media screen and (min-width: 66.667em) {
    .half-and-half {
      -ms-flex-pack: center;
      justify-content: center; } }
  .half-and-half__wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    width: 100%; }
    @media screen and (min-width: 42em) {
      .half-and-half__wrapper {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 77rem; } }
    @media screen and (min-width: 66.667em) {
      .half-and-half__wrapper {
        margin: 0; } }
  .half-and-half__left, .half-and-half__right {
    width: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
    .half-and-half__left .cell-inner, .half-and-half__right .cell-inner {
      width: 100%; }
    .half-and-half__left.bg-img .cell-inner, .half-and-half__right.bg-img .cell-inner {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 109%;
      overflow: hidden;
      position: relative;
      width: 100%; }
      .half-and-half__left.bg-img .cell-inner img, .half-and-half__right.bg-img .cell-inner img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
      .half-and-half__left.bg-img .cell-inner h1, .half-and-half__left.bg-img .cell-inner h2, .half-and-half__left.bg-img .cell-inner h3, .half-and-half__left.bg-img .cell-inner h4, .half-and-half__left.bg-img .cell-inner h5, .half-and-half__left.bg-img .cell-inner h6, .half-and-half__left.bg-img .cell-inner p, .half-and-half__right.bg-img .cell-inner h1, .half-and-half__right.bg-img .cell-inner h2, .half-and-half__right.bg-img .cell-inner h3, .half-and-half__right.bg-img .cell-inner h4, .half-and-half__right.bg-img .cell-inner h5, .half-and-half__right.bg-img .cell-inner h6, .half-and-half__right.bg-img .cell-inner p {
        color: #ffffff; }
    .half-and-half__left:not(.bg-img), .half-and-half__right:not(.bg-img) {
      max-width: 77rem; }
      .half-and-half__left:not(.bg-img) .cell-inner, .half-and-half__right:not(.bg-img) .cell-inner {
        padding-top: 1em; }
  .half-and-half__left.bg-img {
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .half-and-half__right.bg-img {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .half-and-half .half-and-half__left--content,
  .half-and-half .half-and-half__right--content {
    max-width: 42.667rem;
    padding: 1em;
    width: 100%; }
    @media screen and (min-width: 42em) {
      .half-and-half .half-and-half__left--content,
      .half-and-half .half-and-half__right--content {
        padding: 10% 2em; } }
    @media screen and (min-width: 66.667em) {
      .half-and-half .half-and-half__left--content,
      .half-and-half .half-and-half__right--content {
        padding: 15% 5em;
        max-width: 48em; } }
    .half-and-half .half-and-half__left--content img,
    .half-and-half .half-and-half__right--content img {
      vertical-align: bottom; }
    .half-and-half .half-and-half__left--content a,
    .half-and-half .half-and-half__right--content a {
      text-decoration: none; }
  @media screen and (min-width: 42em) {
    .half-and-half.case-studies {
      margin-bottom: 5rem;
      margin-top: 5rem;
      padding-bottom: 2rem; }
      .half-and-half.case-studies .cell-inner {
        padding-top: 0; }
      .half-and-half.case-studies .half-and-half__left {
        width: calc(60% - 1.5rem); }
        .half-and-half.case-studies .half-and-half__left--content {
          padding: 0 1.5rem; }
      .half-and-half.case-studies .half-and-half__right {
        -ms-flex-align: start;
        align-items: flex-start;
        width: calc(40% - 1.5rem); }
        .half-and-half.case-studies .half-and-half__right--content {
          padding: 0; }
      .half-and-half.case-studies:not(:last-child) {
        border-bottom: 1px solid #e5e5e5; } }
  @media screen and (max-width: 41.999em) {
    .half-and-half__left, .half-and-half__right {
      -ms-flex-order: 2;
      order: 2;
      width: 100%; }
      .half-and-half__left.bg-img, .half-and-half__right.bg-img {
        -ms-flex-order: 1;
        order: 1; }
        .half-and-half__left.bg-img .cell-inner, .half-and-half__right.bg-img .cell-inner {
          padding: 0 0 50%; }
      .half-and-half__left.cta, .half-and-half__right.cta {
        padding: 0; }
    .half-and-half.case-studies .half-and-half__right {
      -ms-flex-order: 1;
      order: 1; }
      .half-and-half.case-studies .half-and-half__right--content {
        padding-bottom: 0; }
        .half-and-half.case-studies .half-and-half__right--content p {
          margin-bottom: 0; } }
  .half-and-half .arrow-list,
  .half-and-half .half-half-list {
    background: #ffffff;
    bottom: -1em;
    margin: 0 -1em;
    padding: 0 1rem;
    position: relative; }
    @media screen and (min-width: 42em) {
      .half-and-half .arrow-list,
      .half-and-half .half-half-list {
        background: transparent;
        bottom: 0; } }
  @media screen and (min-width: 60em) {
    .half-and-half.case-studies {
      margin-right: 1.5rem; }
    .half-and-half.two-thirds-left .half-and-half__left {
      width: 60%; }
    .half-and-half.two-thirds-left .half-and-half__right {
      width: 40%; }
    .half-and-half.two-thirds-right .half-and-half__left {
      width: 40%; }
    .half-and-half.two-thirds-right .half-and-half__right {
      width: 60%; } }
  .half-and-half.vertical-top .half-and-half__wrapper {
    -ms-flex-align: start;
    align-items: flex-start; }
  .half-and-half.locations {
    margin: 4em auto;
    padding-top: 1.5rem; }
    .half-and-half.locations .half-and-half__wrapper {
      border-top: 2px solid #008571;
      gap: 6rem;
      max-width: 67rem;
      padding-top: 1.5rem; }
      .half-and-half.locations .half-and-half__wrapper .half-and-half__left--content,
      .half-and-half.locations .half-and-half__wrapper .half-and-half__right--content {
        padding: 0; }
  .half-and-half.partial-bg-left .half-and-half__left--content {
    position: relative; }
    .half-and-half.partial-bg-left .half-and-half__left--content::after {
      background-color: #C2F0E5;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      right: 50%;
      width: 1000%; }
    .half-and-half.partial-bg-left .half-and-half__left--content > * {
      position: relative;
      z-index: 1; }
  .half-and-half.partial-bg-right .half-and-half__right--content {
    position: relative; }
    .half-and-half.partial-bg-right .half-and-half__right--content::after {
      background-color: #C2F0E5;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1000%; }
    .half-and-half.partial-bg-right .half-and-half__right--content > * {
      position: relative;
      z-index: 1; }

/* 
* A gray-themed, 3/2 ratio half and half used for list callout sections
* Note: this class is built to use an image that has a height > width ratio.
* Because of this, images are positioned differently on mobile.
*/
.half-and-half.half-and-half-list-style-callout {
  padding: 0 1em; }
  .half-and-half.half-and-half-list-style-callout .half-and-half__left .cell-inner,
  .half-and-half.half-and-half-list-style-callout .half-and-half__right .cell-inner {
    padding-top: 0; }
  .half-and-half.half-and-half-list-style-callout .half-and-half__left--content,
  .half-and-half.half-and-half-list-style-callout .half-and-half__right--content {
    padding: 0; }
  .half-and-half.half-and-half-list-style-callout .half-and-half__left img,
  .half-and-half.half-and-half-list-style-callout .half-and-half__right img {
    height: 100%;
    width: auto;
    max-width: none; }
    @media screen and (max-width: 41.999em) {
      .half-and-half.half-and-half-list-style-callout .half-and-half__left img,
      .half-and-half.half-and-half-list-style-callout .half-and-half__right img {
        height: auto;
        width: 100%;
        bottom: 0;
        transform: none;
        left: 0;
        bottom: 0;
        top: auto; } }
  .half-and-half.half-and-half-list-style-callout .half-and-half__left {
    background-color: #e5e5e5;
    padding: 2em;
    z-index: 1; }
    @media screen and (min-width: 42em) {
      .half-and-half.half-and-half-list-style-callout .half-and-half__left {
        width: 60%; } }
  .half-and-half.half-and-half-list-style-callout .half-and-half__right {
    padding: 0; }
    @media screen and (min-width: 42em) {
      .half-and-half.half-and-half-list-style-callout .half-and-half__right {
        width: 40%; } }

.overflow-content {
  overflow: visible;
  z-index: 2; }
  @media screen and (min-width: 42em) {
    .overflow-content .half-and-half__left--content,
    .overflow-content .half-and-half__right--content {
      padding: 15% 1rem; } }
  .overflow-content .home-page-style {
    overflow: visible; }
    @media screen and (min-width: 42em) {
      .overflow-content .home-page-style {
        margin-top: -3em;
        margin-bottom: -3em; } }
    @media screen and (min-width: 42em) {
      .overflow-content .home-page-style .cell-inner {
        max-width: 87%; } }
  .overflow-content .cell.bg-img {
    height: 18rem;
    overflow: hidden; }
  @media screen and (max-width: 41.999em) {
    .overflow-content .tab-style.cell {
      margin-top: -3.5em;
      padding: 1em; } }
  .overflow-content .tab-style.cell.half-and-half__right .cell-inner {
    border-bottom: 6px solid #008571;
    margin: 0 auto;
    padding: 2em 1em;
    position: relative;
    height: 100%;
    width: 99%; }
    @media screen and (min-width: 42em) {
      .overflow-content .tab-style.cell.half-and-half__right .cell-inner {
        min-height: 21em; } }
    .overflow-content .tab-style.cell.half-and-half__right .cell-inner:after {
      bottom: 0;
      box-shadow: 0 0 5px 0 rgba(54, 54, 54, 0.5);
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
      @media screen and (max-width: 41.999em) {
        .overflow-content .tab-style.cell.half-and-half__right .cell-inner:after {
          background: #ffffff; } }
  .overflow-content .tab-style.cell.half-and-half__right .half-and-half__right--content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 2em 1em;
    position: relative;
    z-index: 1; }
    @media screen and (min-width: 42em) {
      .overflow-content .tab-style.cell.half-and-half__right .half-and-half__right--content {
        padding: 1em; } }

.fixed-image-half-and-half .half-and-half__wrapper {
  max-width: none; }
  .fixed-image-half-and-half .half-and-half__wrapper .half-and-half__left,
  .fixed-image-half-and-half .half-and-half__wrapper .half-and-half__right {
    height: auto; }

@media screen and (min-width: 42em) {
  .fixed-image-half-and-half .half-and-half__left:not(.bg-img),
  .fixed-image-half-and-half .half-and-half__right:not(.bg-img) {
    margin: 0 auto;
    min-height: 30em;
    max-width: 25em; } }

.fixed-image-half-and-half .half-and-half__left--content,
.fixed-image-half-and-half .half-and-half__right--content {
  padding-left: 1em;
  padding-right: 1em; }

.mobile-overlap {
  position: relative; }
  .mobile-overlap .half-and-half__left, .mobile-overlap .half-and-half__right {
    z-index: 1; }
    .mobile-overlap .half-and-half__left.bg-img, .mobile-overlap .half-and-half__right.bg-img {
      height: 95%;
      position: absolute;
      top: 0;
      z-index: 0; }
      .mobile-overlap .half-and-half__left.bg-img:after, .mobile-overlap .half-and-half__right.bg-img:after {
        content: '';
        height: 100%;
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; }
      @media screen and (min-width: 42em) {
        .mobile-overlap .half-and-half__left.bg-img, .mobile-overlap .half-and-half__right.bg-img {
          position: relative;
          height: auto; }
          .mobile-overlap .half-and-half__left.bg-img:after, .mobile-overlap .half-and-half__right.bg-img:after {
            display: none; } }
    @media screen and (max-width: 41.999em) {
      .mobile-overlap .half-and-half__left h1, .mobile-overlap .half-and-half__left h2, .mobile-overlap .half-and-half__left h3, .mobile-overlap .half-and-half__left h4, .mobile-overlap .half-and-half__left h5, .mobile-overlap .half-and-half__left h6, .mobile-overlap .half-and-half__left p, .mobile-overlap .half-and-half__left span, .mobile-overlap .half-and-half__right h1, .mobile-overlap .half-and-half__right h2, .mobile-overlap .half-and-half__right h3, .mobile-overlap .half-and-half__right h4, .mobile-overlap .half-and-half__right h5, .mobile-overlap .half-and-half__right h6, .mobile-overlap .half-and-half__right p, .mobile-overlap .half-and-half__right span {
        color: #ffffff; } }
  @media screen and (max-width: 41.999em) {
    .mobile-overlap.bg-green .bg-img {
      background: #008571; }
      .mobile-overlap.bg-green .bg-img img {
        opacity: .3; } }
  @media screen and (max-width: 41.999em) {
    .mobile-overlap.bg-purple .bg-img {
      background: #008571; }
      .mobile-overlap.bg-purple .bg-img img {
        opacity: .3; } }
  @media screen and (max-width: 41.999em) {
    .mobile-overlap.bg-pink .bg-img {
      background: #008571; }
      .mobile-overlap.bg-pink .bg-img img {
        opacity: .3; } }

@media screen and (min-width: 42em) {
  .layout-content_with_sidebar + .half-and-half.overflow-content {
    margin-top: 3em; } }

.constrain .half-and-half__left--content,
.constrain .half-and-half__right--content {
  width: 90% !important; }

.sigma-ink-half-and-half .cell-inner {
  padding-top: 0; }

.sigma-ink-half-and-half.half-and-half__left.bg-img .cell-inner img, .sigma-ink-half-and-half.half-and-half__right.bg-img .cell-inner img {
  top: 30%; }
  @media screen and (min-width: 60em) {
    .sigma-ink-half-and-half.half-and-half__left.bg-img .cell-inner img, .sigma-ink-half-and-half.half-and-half__right.bg-img .cell-inner img {
      top: 40%; } }

.home-panes {
  /**
	 * Google isn't liking this animation, so we're not doing it on mobile
	 */ }
  .home-panes__container {
    padding: 0 1.5rem; }
  .home-panes__image {
    margin-bottom: 1.5rem;
    position: relative; }
    .home-panes__image img {
      display: block; }
  .home-panes .home-panes__line {
    position: absolute;
    height: 66%;
    width: 50%; }
    .home-panes .home-panes__line:nth-child(2) {
      border-bottom: 2rem solid white;
      border-right: 1rem solid white;
      top: 0;
      left: 0; }
    .home-panes .home-panes__line:nth-child(3) {
      border-top: 0 solid white;
      border-left: 1rem solid white;
      top: 0;
      right: 0; }
    .home-panes .home-panes__line:nth-child(4) {
      border-bottom: 0 solid white;
      border-right: 1rem solid white;
      bottom: 0;
      left: 0; }
    .home-panes .home-panes__line:nth-child(5) {
      border-top: 2rem solid white;
      border-left: 1rem solid white;
      bottom: 0;
      right: 0; }
  @media screen and (max-width: 41.999em) {
    .home-panes .home-panes__line {
      height: 50%; }
      .home-panes .home-panes__line:nth-child(3) {
        border-top-width: 4rem; }
      .home-panes .home-panes__line:nth-child(4) {
        border-bottom-width: 4rem; } }
  @media screen and (min-width: 42em) {
    .home-panes__container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center; }
    .home-panes__image, .home-panes__text {
      width: 50%; }
    .home-panes__image {
      margin-bottom: 0; }
    .home-panes__text {
      padding-left: 3rem; }
    .home-panes__line {
      transition: 1.75s ease-in-out; }
    .home-panes.in-view .home-panes__line {
      height: 50%; }
      .home-panes.in-view .home-panes__line:nth-child(3) {
        border-top-width: 4rem; }
      .home-panes.in-view .home-panes__line:nth-child(4) {
        border-bottom-width: 4rem; }
      .home-panes.in-view .home-panes__line:nth-child(2) {
        height: 55%; }
      .home-panes.in-view .home-panes__line:nth-child(3) {
        height: 55%; }
      .home-panes.in-view .home-panes__line:nth-child(4) {
        height: 45%; }
      .home-panes.in-view .home-panes__line:nth-child(5) {
        height: 45%; } }
  @media screen and (min-width: 60em) {
    .home-panes__text {
      padding-left: 6rem; } }

.partial-subscribe.layout:last-child {
  margin-bottom: 2rem; }

.partial-subscribe .subscribe-container {
  padding: 0 1.5rem;
  max-width: 35rem; }

.partial-subscribe__link {
  border: 2px solid #008571;
  border-top: none;
  display: block;
  font-weight: bold;
  letter-spacing: 4px;
  margin: 6rem auto 0;
  max-width: 25rem;
  padding: 1rem 5rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: .25s ease-in-out 0s;
  z-index: 2; }
  .partial-subscribe__link:before {
    background: #008571;
    content: '';
    height: 4rem;
    position: absolute;
    bottom: calc(100% + 1rem);
    left: 50%;
    transform: translate(-50%, 0);
    transition: .25s ease-in-out .25s;
    width: 2px; }
  .partial-subscribe__link:after {
    background: #008571;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: .25s ease-in-out 0s;
    width: 100%;
    z-index: -1; }
  .partial-subscribe__link:hover, .partial-subscribe__link:focus {
    color: #ffffff;
    transition: .25s ease-in-out .25s; }
    .partial-subscribe__link:hover:before, .partial-subscribe__link:focus:before {
      transform: translate(-50%, 1rem);
      transition: .25s ease-in-out 0s; }
    .partial-subscribe__link:hover:after, .partial-subscribe__link:focus:after {
      transform: scaleY(1);
      transition: .25s ease-in-out .25s; }

@media screen and (min-width: 42em) {
  .partial-subscribe {
    background: url("/wp-content/themes/kaocollins/library/images/dots.png") no-repeat;
    background-position: 35% 13%;
    background-size: 9rem; } }

.home .half-and-half__wrapper {
  max-width: 65rem; }

@media screen and (min-width: 60em) {
  .home .half-and-half__left--content {
    padding-right: 2rem; }
  .home .half-and-half__right--content {
    padding-left: 2rem; }
  .home .half-and-half__left--content,
  .home .half-and-half__right--content {
    padding-bottom: 3rem; } }

.otgs-development-site-front-end {
  display: none !important; }

.home-panes {
	&__container {@include default-padding;}

	&__image {
		margin-bottom: $spacing;
		position: relative;

		img {display: block;}
	}

	.home-panes__line {
		position: absolute;
			height: 66%;
			width: 50%;

		&:nth-child(2) {
			border-bottom: 2rem solid white;
			border-right: 1rem solid white;
			top: 0;
			left: 0;
		}

		&:nth-child(3) {
			border-top: 0 solid white;
			border-left: 1rem solid white;
			top: 0;
			right: 0;
		}

		&:nth-child(4) {
			border-bottom: 0 solid white;
			border-right: 1rem solid white;
			bottom: 0;
			left: 0;
		}

		&:nth-child(5) {
			border-top: 2rem solid white;
			border-left: 1rem solid white;
			bottom: 0;
			right: 0;
		}
	}

	/**
	 * Google isn't liking this animation, so we're not doing it on mobile
	 */
	@include media($screen-sm-max, 'max') {
		.home-panes__line {
			height: 50%;

			&:nth-child(3) {border-top-width: 4rem;}
			&:nth-child(4) {border-bottom-width: 4rem;}
		}
	}

	@include media($screen-sm) {
		&__container {
			display: flex;
				align-items: center;
		}

		&__image,
		&__text {width: 50%;}

		&__image {margin-bottom: 0;}

		&__text {padding-left: 3rem;}

		&__line {transition: 1.75s ease-in-out;}

		&.in-view {
			.home-panes__line {
				height: 50%;
	
				&:nth-child(3) {border-top-width: 4rem;}
				&:nth-child(4) {border-bottom-width: 4rem;}
	
				&:nth-child(2) {height: 55%}
				&:nth-child(3) {height: 55%}
				&:nth-child(4) {height: 45%}
				&:nth-child(5) {height: 45%}
			}
		}
	}

	@include media($screen-md) {
		&__text {padding-left: 6rem;}
	}
}
/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
	color: $white !important;
	display: inline-block;
	float: right;
	font-family: sans-serif;
	font-size: 1rem;
	height: 2em;
	position: relative;
	text-decoration: none;
	width: 1em;

	strong {
		@include font-scale(-1, $bodyFontSize);
		display: block;
		float:right;
		font-weight: normal;
		text-align: center;
		width: 21em;
	}

	.text {
		background: rgba($black,.9);
		display: inline-block;
		float:left;
		max-width: 0;
		overflow: hidden;
		padding: .25em 0;
		position: absolute;
		right: 2em;
		top: 1px;
		transition: all .5s ease-out;
	}

	a {
		color: $white;
		text-decoration: none;

		&:hover { color: darken($white, 20%); }
	}

	polygon { fill: $white; }

	&.hover span, &:hover span { max-width: 23em; float:left; }
}

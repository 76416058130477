/**
 * General Theme Type classes
 *
 * These classes are generic repeatable patterns of type styles that can be
 * extended by other classes to maintain consistency throughout the theme.
 *
 * Recommended usage:
 * 		@extend .button-type;
 */

$bodyFont: $lato;
$headerFont: $montserrat;

.default-type {
	color: $textColor;
	font-family: $bodyFont;
	letter-spacing: 0.025em;
}

.button-type {
	@extend .default-type;
	font-weight: bold;
}

.header-type {
	color: $headerColor;
    font-family: $headerFont;
	font-weight: bold;
	line-height: $headerLineHeight;
}

.nav-type {
	@extend .header-type;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}


/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
@mixin font-scale($number: 0, $baseSize: $headerFontSize) {
	$size: pow($ratioScale, $number) * $baseSize;
	font-size: $size * .8;
	@media (min-width: $screen-sm) { font-size: $size; }
}


/**
 * Default Typography
 */
html {
	@extend .default-type;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
	scroll-behavior: smooth;

	@media (min-width: $screen-sm) {
		font-size: $bodyFontSize * 0.95;
	}
	@media (min-width: $screen-md) {
		font-size: $bodyFontSize;
	}
	@media (min-width: ($screen-md + $screen-md * .5)) {
		font-size: $bodyFontSize * 1.1;
	}
}

body {
	@extend .default-type;
	font-size: $bodyFontSize;
	font-weight: 400;
	line-height: $bodyLineHeight;
}


nav {
	@extend .header-type;
}


h1, h2, h3, h4, h5, h6 {
	@extend .header-type;
	margin-top: 0;
	margin-bottom: $spacing * 1;
	text-rendering: optimizeLegibility;
	// word-break: break-word;

	a { text-decoration: none; }
}


@mixin h1 {
	@include font-scale(5, $headerFontSize);
	line-height: 1;
	font-weight: 900;
}
.h1, h1 {
	@include h1;
	font-weight: 900;
}

@mixin h2 {
	@include font-scale(3, $headerFontSize);
	font-weight: 700;
}
.h2, h2 {
	@include h2;
	@include media($screen-sm){
		// font-size: $h2Font;
	}
}

@mixin h3 {
	@include font-scale(2, $headerFontSize);
}
.h3, h3 { @include h3; }

@mixin h4 {
	@include font-scale(1, $headerFontSize);
}
.h4, h4 { @include h4; }

@mixin h5 {
	@include font-scale(0, $headerFontSize);
}
.h5, h5 { @include h5; }

@mixin h6 {
	@include font-scale(-1, $headerFontSize);
}
.h6, h6 { @include h6; }



a {
	color: $linkColor;
	transition: color ease-in-out 0.25s;
	word-break: break-word;

	&:hover, &:focus, &:active { color: darken($linkColor, 8%); }
}

p a {
	color: $linkColor;

	&:hover, &:focus, &:active { color: darken($linkColor, 8%); }
}

.large-text__one {
	font-size: 2.7rem;
	@include media($mobileNavBP) {
		font-size: 10vw;
	}
	@include media($screen-xlg) {
		font-size: 9rem;
	}
}

.montserrat {font-family: $montserrat !important;}
.lato {font-family: $lato !important;}

.white {color: $white;}
.black {color: $black;}
.green {color: $darkGreen;}
// .pink {color: $pink;}
.dark-green {color: $darkGreen;}
.light-green {color: $green;}
.graylight{color: $grayLight;}
.gray {color: $gray;}
// .purple-small {color: $purple;}

.thin {font-weight: 400;}
.bold {font-weight: 700;}
.extra-bold {font-weight: 900;}

.caps {text-transform: uppercase;}

hr {
	background: $grayLighter;
	height: 2px;
	margin: 3rem 0;
	width: 100%;
}

@mixin font-size {
	font-size: 2.05rem;
	@include media($screen-lg){
		font-size: 2.5rem;
	}
}

h2.big-white,
.h2.big-white {
	color: $white;
	@include font-size;
	@at-root .partial-homepage-header .big-white {
		@include h2;
	}
}

h2.big-purple,
.h2.big-purple {
	// color: $purple;
	@include font-size;
}

h2.big-pink {
	// color: $pink;
	@include font-size;
}

h2.big-green {
	color: $adaGreen !important;
	@include font-size;
}

h2.big-green-dark {
	color: $darkGreen;
	@include font-size;
}

.bold {
	font-weight: 500;
}

em {
	color: $grayLight;
	font-size: 2rem;
	font-style: normal;
}

p {
	margin-top: 0;
	margin-bottom: $spacing;
}

.eyebrow {
	font-family: $headerFont;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 4.27px;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

.dropcap {
	color: $primaryColor;
	float: left;
	font-size: 6.25em;
	font-weight: 400;
	line-height: 90px;
	margin-bottom: -.031em;
	margin-top: .03em;
	padding-right: .5rem;
}

.font_small,
small { font-size: 0.707em; }

.font-size-xs, .fs-xs { font-size: .875rem; }
.font-size-sm, .fs-sm { font-size: .9375rem; }
.font-size-md, .fs-md { font-size: 1rem; }
.font-size-lg, .fs-lg, .intro-text { font-size: 1.125rem; }
.font-size-xl, .fs-xl { font-size: 1.25rem; }
.font-size-xxl, .fs-xxl { font-size: 1.375rem; }
.font-size-xxxl, .fs-xxxl { font-size: 1.5rem; }

.uppercase {
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

.assistive,
.screen-reader-text,
.hide-text {
	@include size(1px);
	border: 0;
	clip: rect(0 0 0 0);
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

.screen-reader-text:active,
.screen-reader-text:focus {
	@include size(auto);
	clip: auto;
	margin: 0;
	overflow: visible;
	position: static;
}
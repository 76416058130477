/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */

// Vendor Based Styles
$disable-warnings: true;
$output-bourbon-deprecation-warnings: false !default;
@import "bourbon";

// Base Styles
@import "mixins/mixins";
@import "base/vars";
@import "base/fonts";
@import "base/type";

// Components
@import "components/animations";
@import "components/dbs-chevron";
@import "components/footer";

// Layout Styles
@import "layout/container";
@import "layout/layout";
@import "layout/layout-featured-inks";
@import "layout/layout-half-and-half";
@import "layout/layout-home-panes";


// Partials
@import "layout/partials/partial-subscribe";

// Page
@import "pages/front-page";

.otgs-development-site-front-end {display: none !important;}
.partial-subscribe {
	&.layout:last-child {margin-bottom: 2rem;}

	.subscribe-container {
		@include default-padding;
		max-width: 35rem;
	}

	&__link {
		border: 2px solid $darkGreen;
		border-top: none;
		display: block;
		font-weight: bold;
		letter-spacing: 4px;
		margin: 6rem auto 0;
		max-width: 25rem;
		padding: 1rem 5rem;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;
		transition: .25s ease-in-out 0s;
		z-index: 2;

		&:before {
			background: $darkGreen;
			content: '';
			height: 4rem;
			position: absolute;
				bottom: calc(100% + 1rem);
				left: 50%;
			transform: translate(-50%, 0);
			transition: .25s ease-in-out .25s;
			width: 2px;
		}

		&:after {
			background: $darkGreen;
			content: '';
			height: 100%;
			position: absolute;
				top: 0;
				left: 0;
			transform: scaleY(0);
			transform-origin: top;
			transition: .25s ease-in-out 0s;
			width: 100%;
			z-index: -1;
		}

		&:hover,
		&:focus {
			color: $white;
			transition: .25s ease-in-out .25s;

			&:before {
				transform: translate(-50%, 1rem);
				transition: .25s ease-in-out 0s;
			}

			&:after {
				transform: scaleY(1);
				transition: .25s ease-in-out .25s;
			}
		}
	}

	@include media($screen-sm) {
		background: url('/wp-content/themes/kaocollins/library/images/dots.png') no-repeat;
			background-position: 35% 13%;
			background-size: 9rem;
	}
}
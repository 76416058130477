/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

#site-footer {
	margin-top: auto; // Sticky footer
	background: #202020;
	color: $white;
	overflow: hidden;
	padding: 0;

	@include media($screen-md) {font-size: .875em;}

	#site-footer__logo img {
		max-height: 3em; // ie fix
		max-width: 12em;
		display: block;
		width: 100%;
	}

	//UL LI RESET
	ul {
		list-style: none;
		padding: 0;
		margin:0;
	}
	//link Reset
	a {
		color: $white;
		display: inline-block;
		font-family: $lato;
		font-weight: 400;
		padding: 0;
		text-decoration: none;
		word-break: normal;

		&:hover,
		&:focus {
			color: $darkGreen;
		}
	}

	// Footer column headings
	.footer-heading {
		color: $white;
		display: block;
		font-family: $montserrat;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: .5rem;
	}

	#site-footer__content {
		#container-top {
			border-top: 1px solid rgba(59, 74, 89, .4);
			margin: 0 auto;
			max-width: $containerSize;
			padding: 1em 0;

			@include media ($screen-md) {
				display: flex;
					justify-content:space-between;
			}

			.footer-column {
				display: inline-block;
				width: 100%;
				padding: 2rem 1rem;
				border-bottom: 1px solid rgba($white, .4);

				@include media ($screen-md) {
					border: none;
					padding: 2rem 3vw;
					width: 33.33%;

					&#middle-column {
						position: relative;

						&:before,
						&:after {
							content: '';
							height: 85%;
							position: absolute;
								top: 50%;
							transform: translateY(-50%);
						}

						&:before {
							border-left: 1px solid rgba($white, .4);
							left: 0;
						}

						&:after {
							border-right: 1px solid rgba($white, .4);
							right: 0;
						}
					}
				}
			}

			#first-row {

				#addresses {
					&> div {margin: 2em 0;}
				}
			}

			#second-row {

				#nav-wrapper {
					display: flex;
						flex-direction: row;
						justify-content:space-between;
					margin-bottom: 2em;
			
					#site-footer-nav__main-menu{
						width: 100%;
			
						 li {
							display: block;
							padding-bottom: 0;
		
							@include media ($screen-md) {padding-bottom: .5em;}
		
							button {
								display: none; //Don't display sub menus list on footer menus
							}
						}
					}
				}
			}

			#fourth-row {
				a {display: block;}
			}

			#second-row,
			#fourth-row {
				a {padding: .25em 0;}
			}
		}
	}

	#site-footer__social-media {
		.site-footer__social-link {
			background: transparent;
			border: 1px solid $white;
			border-radius: 100%;
			display: inline-block;
			font-size: 1.2em;
			height: 2em;
			line-height: 1.9;
			margin-left: .5rem;
			text-align: center;
			transition: .35s all;
			width: 2em;
	
			svg {
				fill: $white;
				height: 100%;
				width: 0.8em;
				height: auto;
				transition: fill .35s;
			}
	
			&:hover, &:focus {
				border: 1px solid $green;
	
				svg {fill: $green;}
			}
		}
	}

	#site-footer__credits {
		padding: 1em;

		.copyright {margin-bottom: 1rem;}

		@include media($screen-md) {
			border-top: 1px solid rgba($white, .4);
			display: flex;
				align-items: center;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
			font-size: .8rem;

			#site-footer__social-media {
                margin-left: auto;
                margin-right: auto;
            }

			.copyright {margin-bottom: 0;}
		}

		@include media(76rem) {
			font-size: 1rem;
		}

		#navigation {
			display: flex;
				align-items: center;
				justify-content: space-between;
			margin-top: 1em;

			@include media($screen-md) {
				margin-top: 0em;
			}
			nav {
				@include media($screen-md) {
					padding: 0 2em;
				}
			}

			li {
				border-right: 1px solid rgba(59, 74, 89, .4);
				display: inline-block;
				margin-right: .5em;
				padding-right: .75em;

				&:last-child {border: none;}

				@include media($screen-sm-max, 'max') {
					margin-bottom: .75rem;
				}
			}
		}
	}

	// Containers
	.container-top,
	.site-footer__credits {
		margin: 0 auto;
	}

	.container-top {max-width: $containerSizeWide;}

	.site-footer__credits {max-width: calc(#{$containerSizeWide} - 4rem);}
}

.wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {display: none;}
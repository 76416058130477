/**
 * Half and half flex layout
 * DBS>Interactive
 */
.half-and-half {
	display: flex;
	justify-content: space-between;
	@include media($screen-lg) {
		justify-content: center;
	}

	&__wrapper {
		display: flex;
			flex-direction: column;
		margin: 0 auto;
		width: 100%;

		@include media($screen-sm) {
			flex-direction: row;
			justify-content: space-between;
			margin: 0 auto;
			max-width: $containerSize;
		}

		@include media($screen-lg) {
			margin: 0; // IE Fix
		}
	}
	//Default Setting
	&__left,
	&__right {
		@include grid(6);
		// 2 in a row 6/12 each
		display: flex;
			align-items: center;
		.cell-inner {
			width: 100%;
		}

		&.bg-img {
			.cell-inner {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				height: 109%;
				overflow: hidden;
				position: relative;
				width: 100%;

				img {
					position: absolute;
						left: 50%;
						top: 50%;
					transform: translate(-50%,-50%);
				}

				h1, h2, h3, h4, h5, h6, p {
					color: $white;
				}
			}
		}
		&:not(.bg-img) {
			max-width: $containerSize;
			.cell-inner {
				padding-top: 1em;
			}
		}
	}

	&__left.bg-img {
		justify-content: flex-start;
	}

	&__right.bg-img {
		justify-content: flex-end;
	}

	.half-and-half__left--content,
	.half-and-half__right--content {
		max-width: $containerSizeNarrow;
		padding: 1em;
		width: 100%;
		@include media($screen-sm) {
			padding: 10% 2em;
		}
		@include media($screen-lg) {
			padding: 15% 5em;
			max-width: 48em;
		}

		img {
			vertical-align: bottom;
		}

		a {
			text-decoration: none;
		}
	}
    
    @include media($screen-sm) { 
        &.case-studies {
            margin-bottom: 5rem;
            margin-top: 5rem;
            padding-bottom: 2rem;
            
            .cell-inner {
                padding-top: 0;
            }

            .half-and-half__left {
                width: calc(60% - #{$spacing});
                
                &--content {
                    padding: 0 $spacing;
                }
            }
            
            .half-and-half__right {
                align-items: flex-start;
                width: calc(40% - #{$spacing});
                
                &--content {
                    padding: 0;
                }
            }
            
            &:not(:last-child) {
                border-bottom: 1px solid $grayLighter;
            }
        }
    }

	@include media ($screen-sm-max, max) {
		&__left,
		&__right {
			order: 2;
			width: 100%;

			&.bg-img {
				order: 1;

				.cell-inner {
					padding: 0 0 50%;
				}
			}

			&.cta {
				padding: 0;
			}
		}
        
        &.case-studies {
            .half-and-half__right {
                order: 1;

                &--content {
                    padding-bottom: 0;
                    
                    p {
                        margin-bottom: 0;
                    }
                }
            }            
        }
	}

	.arrow-list,
	.half-half-list {
		background: $white;
		bottom: -1em;
		margin: 0 -1em;
		padding: 0 1rem;
		position: relative;
		@include media($screen-sm) {
			background: transparent;
			bottom: 0;
		}
	}
    
    @include media($screen-md) {
        &.case-studies {
            margin-right: $spacing;
        }
        
        &.two-thirds-left {
            .half-and-half__left {width: 60%;}
            .half-and-half__right {width: 40%;}
        }

        &.two-thirds-right {
            .half-and-half__left {width: 40%;}
            .half-and-half__right {width: 60%;}
        }
    }
    
    &.vertical-top {
        .half-and-half__wrapper {
            align-items: flex-start;
        }
    }
    
    &.locations {
        margin: 4em auto;
        padding-top: 1.5rem;
        
        .half-and-half__wrapper {
            border-top: 2px solid #008571;
            gap: 6rem;
            max-width: 67rem;
            padding-top: 1.5rem;
            
            .half-and-half__left--content,
            .half-and-half__right--content {
                padding: 0;
            }
        }
    }
    
    &.partial-bg-left {
        .half-and-half__left--content {
            position: relative;

            &::after {
                background-color: #C2F0E5;
                content: '';
                height: 100%;
                position: absolute;
                    top: 0;
                    right: 50%;
                width: 1000%;
            }
            
            &>* {
                position: relative;
                z-index: 1;
            }
        }
    }
    
    &.partial-bg-right {
        .half-and-half__right--content {
            position: relative;

            &::after {
                background-color: #C2F0E5;
                content: '';
                position: absolute;
                    top: 0;
                    left: 50%;
                height: 100%;
                width: 1000%;
            }
            
            &>* {
                position: relative;
                z-index: 1;
            }
        }
    }
}

//End Of Global Half and Half
// This class will constrain half/half content to give padding between
// halves and prevent full-width layout.

/* 
* A gray-themed, 3/2 ratio half and half used for list callout sections
* Note: this class is built to use an image that has a height > width ratio.
* Because of this, images are positioned differently on mobile.
*/
.half-and-half.half-and-half-list-style-callout {
	padding: 0 1em;
	.half-and-half__left,
	.half-and-half__right {
		.cell-inner {
			padding-top: 0;
		}
		&--content {
			padding: 0;
		}

		img {
			height: 100%;
			width: auto;
			max-width: none;
			@include media($screen-sm-max, max) {
				height: auto;
				width: 100%;
				bottom: 0;
				transform: none;
				left: 0;
				bottom: 0;
				top: auto;
			}
		}
	}

	.half-and-half__left {
		background-color: $grayLighter;
		padding: 2em;
		z-index: 1;
		@include media($screen-sm) {
			width: 60%;
		}
	}

	.half-and-half__right {
		padding: 0;
		@include media($screen-sm) {
			width: 40%;
		}
	}
}

.overflow-content {
	overflow: visible;
	z-index: 2;

	.half-and-half__left--content,
	.half-and-half__right--content {
		@include media($mobileNavBP) {
			padding: 15% 1rem;
		}
	}

	.home-page-style {
		overflow: visible;
		@include media($mobileNavBP) {
			margin-top: -3em;
			margin-bottom: -3em;
		}

		.cell-inner {
			@include media($screen-sm) {
				max-width: 87%;
			}
		}
	}
	// tab-style
	//Overflow Content Layout on Main Section Left With Sidebar On Right
	.cell.bg-img {
		height: 18rem; //Fixed Height
		overflow: hidden;
	}

	.tab-style.cell {
		@include media($screen-sm-max, 'max') {
			margin-top: -3.5em;
			padding: 1em;
		}
		&.half-and-half__right {
			.cell-inner {
				border-bottom: 6px solid $darkGreen;
				margin: 0 auto;
				padding: 2em 1em;
				position: relative;
				height: 100%;
				width: 99%;
				@include media($screen-sm) {
					min-height: 21em;
				}

				&:after {
					bottom: 0;
					box-shadow: 0 0 5px 0 rgba(54,54,54,.5);
					content: '';
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					@include media($screen-sm-max, 'max') {
						background: $white;
					}
				}
			}

			.half-and-half__right--content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 2em 1em; // Reset
				position: relative;
				z-index: 1;
				@include media($screen-sm) {
					padding: 1em; // Reset
				}
			}
		}
	}
}

// ACF Classes
.fixed-image-half-and-half {
	.half-and-half__wrapper {
		max-width: none;
		.half-and-half__left,
		.half-and-half__right {
			height: auto;
		}
	}

	.half-and-half__left:not(.bg-img),
	.half-and-half__right:not(.bg-img) {
		@include media($screen-sm) {
			margin: 0 auto;
			min-height: 30em;
			max-width: 25em;
		}
	}

	.half-and-half__left--content,
	.half-and-half__right--content {
		padding-left: 1em;
		padding-right: 1em
	}

}

//Text Over the Background Image on Half Half only for mobile
.mobile-overlap {
	position: relative;

	.half-and-half {
		&__left,
		&__right {
			z-index: 1;

			&.bg-img {
				height: 95%;
				position: absolute;
					top: 0;
				z-index: 0;

				&:after {
					content: '';
					height: 100%;
					opacity: 0.7;
					position: absolute;
						top: 0;
						left: 0;
					width: 100%;
				}
				@include media($screen-sm) {
					position: relative;
					height: auto;

					&:after {
						display: none;
					}
				}
			}
			h1, h2, h3, h4, h5, h6, p, span {
				@include media($screen-sm-max, 'max') {
					color: $white;
				}

			}
		}
	}

	//If there is bg-green class
	&.bg-green {
		.bg-img {
			@include media($screen-sm-max, 'max') {
				background: $darkGreen;
				img {
					opacity: .3;
				}
			}

		}
	}
	//End of -green background
	//If there is bg-purple class
	&.bg-purple {
		.bg-img {
			@include media($screen-sm-max, 'max') {
				background: $darkGreen;
				img {
					opacity: .3;
				}
			}
		}
	}
	//If there is a bg-pink class
	&.bg-pink {
		.bg-img {
			@include media($screen-sm-max, 'max') {
				background: $darkGreen;
				img {
					opacity: .3;
				}
			}
		}
	}
}
//End of mobile-overlap

.layout-content_with_sidebar + .half-and-half.overflow-content {
	@include media($screen-sm) {
		margin-top: 3em; // Prevents overlapping on desktop
	}
}

.constrain {
	.half-and-half__left--content,
	.half-and-half__right--content {
		width: 90% !important;
	}
}

.sigma-ink-half-and-half {
    .cell-inner {
        padding-top: 0;
    }
    &.half-and-half__left.bg-img .cell-inner img, &.half-and-half__right.bg-img .cell-inner img {
        top: 30%;
        @include media($screen-md) {
            top: 40%;
        }
    }
}

.featured-inks {
	&__container {@include default-padding;}

	&__heading {
		margin-bottom: 2rem;
	}

	&__block {
		&-img {
			height: 0;
			margin-bottom: 1rem;
			overflow: hidden;
			padding-bottom: 36%;
			position: relative;

			img {
				@include object-fit(cover, center);
			}
		}	

		&-text {
			padding: 0 1rem;

			a {
				color: $black !important;
				font-weight: bold;
				text-decoration: none;
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		&__block {
			margin-bottom: 2rem;
		}
	}

	@include media($screen-sm) {
		&__heading {margin-bottom: 3rem;}

		&__blocks {
			display: flex;
			flex-wrap: wrap;
				justify-content: space-between;
		}

		&__block {
			padding-bottom: 1rem;
			position: relative;
			width: calc(50% - 1.5rem);

			&:after {
				@include size(1.75rem);
				background: url('/wp-content/themes/kaocollins/library/icons/src/circle-right1-green.svg') no-repeat center;
					background-size: contain;
				content: '';
				position: absolute;
					bottom: 0;
					left: 1rem;
				transition: all .25s;
			}

			&:hover,
			&:focus {
				&:after {transform: translateX(.25rem);}
			}
		}
	}

	@include media($screen-md) {
		&__block {
			width: calc(25% - 3rem);
		}
	}
}
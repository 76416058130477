/**
 * Default layout styles
 * DBS>Interactive
 */


/**
 * Default responsive padding mixin for layout content
 */
@mixin default-padding {
	padding: 0 $spacing;
}

@mixin cell-space-between($space_property: margin, $reverse: false) {
	@if $reverse {
		#{$space_property}: ($spacing * 2) $spacing ($spacing * 2) 0;
		&:last-of-type { #{$space_property}-left: 0; }
	} @else {
		#{$space_property}: ($spacing * 2) 0 ($spacing * 2) $spacing;
		&:last-of-type { #{$space_property}-right: 0; }
	}
}

.site {
	// Sticky footer styles
	display: flex;
		flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
}


.site-content {
	background: $white;
	clear: both;
}


/**
 * Basic generic styles for all layouts
 */

.layout {
	overflow: hidden;

	&:last-child {
		margin-bottom: 0;
	}

	.blockquote {
		text-align: left;
		max-width: 900px;
		font-weight: 500;
	  	margin: 5em auto;
	  	padding: 0.5em 10px;
		padding-left: 40px;
		border: none;
	}

	blockquote {
		border: none;
	}

	.blockquote::before {
		content: open-quote;
		position: absolute;
		margin-top: -0.5em;
		font-size: 7em;
		font-family: Georgia;
	}
}

.layout:not(.layout-content_with_sidebar) {
   .layout:last-child.overflow-content {
	   margin-bottom: 0;
   }
}
// .layout {
// 	p:last-of-type { margin-bottom: 0; }
// }

.thumbnail-text .cell {
	display: flex;
	align-items: flex-start;

	// Give margin-right to the first item (image or content)
	& > :first-child { margin-right: 1em; }

	& > img { max-width: 25%; }
	& > .content { flex: 1; }
}


.text-card .cell,
.image-card .cell {
	@include cell-space-between;
	padding: 0;

	&:first-of-type { margin-left: 0; }

	.content { @include default-padding; }
}
